import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Link from "../components/link"
import Button from "../components/button"
import { Heading1, Heading2 } from "../components/styled/heading"

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${rem(240)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${rem(120)};
  }
`

const List = styled.div`
  max-width: ${rem(880)};
  margin: 0 auto;
  padding-top: ${em(40)};
`

const Service = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
  }

  & + & {
    margin-top: ${em(-20)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(60)};
    }
  }

  > .-title {
    padding-right: ${em(80)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-right: 0;
      order: 1;
      text-align: center;
    }

    a {
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.blue};
      }

      &:active {
        opacity: 0.5;
      }
    }
  }

  > .-img {
    position: relative;

    > a {
      position: absolute;
      z-index: 1;
      top: 75%;
      left: 2.5%;

      @media ${({ theme }) => theme.mq.mediumDown} {
        left: auto;
        right: 2.5%;
      }
    }
  }

  .gatsby-image-wrapper {
    width: 70vw;
    height: 70vw;
    max-width: ${em(380)};
    max-height: ${em(380)};
    border-radius: 50%;

    /* fix for safari */
    img {
      border-radius: 50%;
      overflow: hidden;
    }
  }
`

const ServicesPage = ({ data }) => {
  const anyServices = data.services.nodes.length > 0
  const metaDescription = anyServices
    ? data.services.nodes.map((i) => i.frontmatter.title).join(`; `)
    : undefined

  return (
    <Layout>
      <Meta
        data={{
          title: `Paslaugos`,
          description: metaDescription,
        }}
      />

      <Container>
        <Heading1>Paslaugos</Heading1>

        {anyServices && (
          <List>
            {data.services.nodes.map((service, i) => (
              <Service key={i}>
                <header className="-title">
                  <Heading2>
                    <Link to={`/${service.slug}`}>
                      {service.frontmatter.title}
                    </Link>
                  </Heading2>
                </header>

                {service.frontmatter.image && (
                  <figure className="-img">
                    <Button to={`/${service.slug}`} size="small">
                      Plačiau
                    </Button>

                    <GatsbyImage
                      image={
                        service.frontmatter.image.childImageSharp
                          .gatsbyImageData
                      }
                      alt={service.frontmatter.title}
                    />
                  </figure>
                )}
              </Service>
            ))}
          </List>
        )}
      </Container>
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.object,
}

export default ServicesPage

export const pageQuery = graphql`
  query {
    services: allMarkdownRemark(
      filter: { type: { eq: "paslaugos" } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        slug
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Link from "../components/link"
import Styled from "../components/styled"

const Container = styled.article`
  ${({ theme }) => theme.grid.container()}

  p {
    @media ${({ theme }) => theme.mq.mediumUp} {
      margin-left: ${rem(60)};
    }
  }
`

const NotFoundPage = () => {
  return (
    <Layout>
      <Meta data={{ title: `Tokio puslapio nėra` }} />

      <Container>
        <Styled>
          <h1>Tokio puslapio nėra</h1>

          <p>
            Eikite į <Link to="/">titulinį puslapį</Link> ✌️
          </p>
        </Styled>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Link from "../components/link"
import Styled from "../components/styled"
import { Heading1 } from "../components/styled/heading"

const Container = styled.article`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${em(100)};
`

const Header = styled.header`
  max-width: ${em(1024)};
  padding-right: ${em(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-right: 0;
    padding-bottom: ${em(20)};
  }
`

const Date = styled.time`
  margin-top: ${em(30)};
  display: block;
  opacity: 0.7;
`

const Image = styled.figure`
  margin-top: ${rem(30)};
  padding: 0 ${rem(60)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(10)};
    padding: 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const Text = styled.div`
  margin-top: ${rem(30)};
  padding: 0 ${rem(60)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    padding: 0;
  }

  > div {
    column-count: 2;
    column-gap: ${rem(100)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      column-count: 1;
    }
  }
`

const Gallery = styled.ul`
  padding: ${rem(60)};
  padding-bottom: ${rem(30)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(100)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const NewsPostPage = ({ data: { post } }) => {
  return (
    <Layout headerActiveNavItem="/naujienos">
      <Meta
        data={{
          title: post.frontmatter.title,
          description: post.html,
          socialImage:
            post.frontmatter.image &&
            post.frontmatter.image.childImageSharp.gatsbyImageData.images
              .fallback.src,
        }}
      />

      <Container>
        <Header>
          <Heading1
            dangerouslySetInnerHTML={{ __html: post.frontmatter.title }}
          />
        </Header>

        {post.frontmatter.image && (
          <Image className="-img">
            <GatsbyImage
              image={post.frontmatter.image.childImageSharp.gatsbyImageData}
              alt={post.frontmatter.title}
            />
          </Image>
        )}

        <Text>
          <Styled dangerouslySetInnerHTML={{ __html: post.html }} />
        </Text>

        {post.frontmatter.gallery && !!post.frontmatter.gallery.length && (
          <Gallery>
            {post.frontmatter.gallery.map((image) => (
              <li key={image.id}>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={post.frontmatter.title}
                />
              </li>
            ))}
          </Gallery>
        )}

        <Date
          dateTime={post.frontmatter.date}
          dangerouslySetInnerHTML={{ __html: post.frontmatter.date }}
        />

        <Text>
          <Styled>
            <p>
              <Link to="/naujienos">&larr; Visos naujienos</Link>
            </p>
          </Styled>
        </Text>
      </Container>
    </Layout>
  )
}

NewsPostPage.propTypes = {
  data: PropTypes.object,
}

export default NewsPostPage

export const pageQuery = graphql`
  query ($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        gallery {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

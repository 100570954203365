import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { em, rem, hideVisually } from "polished"

import Link from "../link"
import Button from "../button"
import { Heading1 } from "../styled/heading"

let containerMinHeight = 0

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}
`

const Service = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
  }

  ${({ active }) =>
    !active &&
    css`
      ${hideVisually()}
    `}

  ${({ active }) =>
    active &&
    css`
      .-title {
        animation-name: ${({ theme }) => theme.animations.fadeIn},
          ${({ theme }) => theme.animations.slideInX(-20)};
      }

      .gatsby-image-wrapper {
        animation-name: ${({ theme }) => theme.animations.fadeIn},
          ${({ theme }) => theme.animations.slideInX(20)};
      }
    `}

  .-title,
  .gatsby-image-wrapper {
    animation-timing-function: ${({ theme }) => theme.easings.default};
    animation-fill-mode: forwards;
    animation-duration: 2s;
    will-change: transform;
  }

  .-title {
    padding: 0 ${em(40)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(40)};
      padding: 0;
      order: 1;
      text-align: center;
    }

    a {
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.blue};
      }

      &:active {
        opacity: 0.5;
      }
    }

    &__num {
      margin-top: ${em(20)};
      opacity: 0.6;
    }
  }

  .-img {
    margin-right: ${em(-80)};
    position: relative;

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-right: 0;
    }

    &::before {
      content: "";
      width: 100vw;
      height: 100vw;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.yellowLightest};
      border-radius: 50%;

      @media ${({ theme }) => theme.mq.mediumDown} {
        width: 150vw;
        height: 150vw;
      }
    }

    > a {
      position: absolute;
      z-index: 2;
      top: 75%;
      left: 2.5%;

      @media ${({ theme }) => theme.mq.mediumDown} {
        left: auto;
        right: 2.5%;
        font-size: ${em(18)};
      }
    }

    .gatsby-image-wrapper {
      width: 58vw;
      height: 58vw;
      max-width: ${em(640)};
      max-height: ${em(640)};
      overflow: hidden;
      border-radius: 50%;
      z-index: 1;

      @media ${({ theme }) => theme.mq.mediumDown} {
        width: 70vw;
        height: 70vw;
      }
    }
  }
`

const ServicesAnnouncer = () => {
  const { services } = useStaticQuery(graphql`
    query {
      services: allMarkdownRemark(
        filter: { type: { eq: "paslaugos" } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          slug
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  const containerRef = useRef()
  const intervalRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)

  if (!services.nodes.length) return null

  const changeActive = () => {
    let index = activeIndex + 1
    if (index >= services.nodes.length) index = 0
    setActiveIndex(index)
  }

  const setMinHeight = () => {
    containerRef.current.style.minHeight = `auto`
    const height = Math.max(
      containerRef.current.getBoundingClientRect().height,
      containerMinHeight
    )
    containerRef.current.style.minHeight = rem(height)
    containerMinHeight = height
  }

  const winResize = () => {
    containerMinHeight = 0
    setMinHeight()
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      intervalRef.current = window.setInterval(changeActive, 3000)
      window.addEventListener(`resize`, winResize, { passive: true })
      setMinHeight()
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.clearInterval(intervalRef.current)
        window.removeEventListener(`resize`, winResize, { passive: true })
      }
    }
  })

  return (
    <Container ref={containerRef}>
      {services.nodes.map((service, i) => (
        <Service key={i} active={i == activeIndex}>
          <header className="-title">
            <Heading1 as="h2">
              <Link to={`/${service.slug}`}>{service.frontmatter.title}</Link>
            </Heading1>

            <div className="-title__num">
              {i + 1} / {services.nodes.length}
            </div>
          </header>

          {service.frontmatter.image && (
            <figure className="-img">
              <Button to={`/${service.slug}`}>Plačiau</Button>

              <GatsbyImage
                image={
                  service.frontmatter.image.childImageSharp.gatsbyImageData
                }
                alt={service.frontmatter.title}
              />
            </figure>
          )}
        </Service>
      ))}
    </Container>
  )
}

export default ServicesAnnouncer

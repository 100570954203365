import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import formUrlEncoded from "form-urlencoded"

import Button from "../button"
import Field from "../formik/field"
import FocusError from "../formik/focus-error"

const Container = styled.div`
  padding: ${em(40)};
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.colors.yellowLightest};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(40)} ${em(80)};
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    padding: ${em(20)};
  }
`

const Row = styled.div`
  margin-top: ${em(20)};

  ${({ button }) =>
    button &&
    css`
      margin-top: 0;
      transform: translateY(50%);
      display: flex;
      justify-content: flex-end;
    `}
`

const Success = styled.p`
  padding: ${em(20)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
`

const Error = styled.p`
  padding: ${em(20)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red};
`

const ContactForm = () => {
  const [status, setStatus] = useState(``)
  const formRef = useRef()

  const formName = `kontaktai`

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    tel: Yup.string().phone().required(),
    message: Yup.string().required(),
  })

  const initialValues = {
    name: ``,
    email: ``,
    tel: ``,
    message: ``,
    "form-name": formName,
  }

  const submit = (values, { resetForm }) => {
    window
      .fetch(`/`, {
        method: `POST`,
        headers: { "Content-Type": `application/x-www-form-urlencoded` },
        body: formUrlEncoded(values),
      })
      .then(() => {
        setStatus(`success`)
        resetForm()
      })
      .catch(() => setStatus(`error`))
  }

  return (
    <Container>
      {status === `success` && <Success>Dėkojame už Jūsų žinutę!</Success>}

      {status === `error` && (
        <Error>
          Išsiųsti nepavyko, pamėginkite dar kartą arba susisiekite šiame
          puslapyje pateiktais kontaktais. Ačiū!
        </Error>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {() => (
          <Form ref={formRef} name={formName} data-netlify="true">
            <FocusError formEl={formRef.current} />

            <Row>
              <Field
                name="name"
                type="text"
                label="Jūsų vardas"
                placeholder="Jūsų vardas"
              />
            </Row>

            <Row>
              <Field
                name="email"
                type="email"
                label="El. paštas"
                placeholder="El. paštas"
              />
            </Row>

            <Row>
              <Field
                name="tel"
                type="tel"
                label="Telefono nr."
                placeholder="Telefono nr."
              />
            </Row>

            <Row>
              <Field
                name="message"
                component="textarea"
                label="Žinutė"
                placeholder="Žinutė"
              />
            </Row>

            <Row button={true}>
              <Button type="submit" size="small">
                Siųsti
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ContactForm

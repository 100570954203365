import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { em } from "polished"

import Link from "../link"

const Container = styled.button`
  ${({ theme }) => theme.fonts.set(`secondary`, `bold`)};

  width: ${em(90)};
  height: ${em(90)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${em(22)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  transition: transform 0.3s ${({ theme }) => theme.easings.default};

  &:hover,
  &:focus {
    transform: scale(1.05) rotate(5deg);
  }

  &:active {
    opacity: 0.5;
  }

  ${(props) =>
    props.size == `large` &&
    css`
      width: ${em(120)};
      height: ${em(120)};
      font-size: ${em(28)};
    `}

  ${(props) =>
    props.size == `small` &&
    css`
      width: ${em(70)};
      height: ${em(70)};
      font-size: ${em(20)};
    `}
`

const Button = ({ children, to, ...rest }) => {
  return (
    <Container as={to ? Link : undefined} to={to} {...rest}>
      <span>{children}</span>
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export default Button

import styled, { css } from "styled-components"
import { em } from "polished"

const liStyles = css`
  margin-left: ${em(18)};
  font-size: ${em(20)};
  line-height: 1.55;
  word-break: break-word;
  text-align: justify;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(18)};
  }

  &:not(:last-child) {
    margin-bottom: ${em(10)};
  }

  &:not(:frst-child),
  ul,
  ol {
    margin-top: 0.5em;
  }
`

const uListStyles = css`
  list-style-type: disc;

  > li {
    ${liStyles}
  }
`

const oListStyles = css`
  list-style-type: decimal;

  > li {
    ${liStyles}
  }
`

const dListStyles = css`
  font-size: ${em(20)};
  line-height: 1.55;
  word-break: break-word;

  dt {
    display: block;

    &:not(:first-child) {
      margin-bottom: 0.5em;
    }
  }

  dd {
    display: block;
    margin-left: 1.5em;
  }
`

const UList = styled.ul`
  ${uListStyles}
`

const OList = styled.ol`
  ${oListStyles}
`

const DList = styled.dl`
  ${dListStyles}
`

export { UList, OList, DList, uListStyles, oListStyles, dListStyles }

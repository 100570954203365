import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Link from "../components/link"
import Styled from "../components/styled"
import { Heading1, Heading2 } from "../components/styled/heading"
import { Anchor } from "../components/styled/anchor"
import ContactForm from "../components/contact-form"

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${rem(240)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${rem(120)};
  }
`

const Cols = styled.div`
  padding: ${rem(60)};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(20)} 0 0;
  }

  > div {
    width: calc(50% - ${rem(50)});

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: 100%;
    }

    &:not(:last-child) {
      @media ${({ theme }) => theme.mq.mediumDown} {
        margin-bottom: ${rem(40)};
      }
    }
  }
`

const Contacts = styled(Heading2)`
  li + li {
    margin-top: ${rem(10)};
  }
`

const Requisites = styled(Styled)`
  margin-top: ${rem(60)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
  }
`

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <Meta
        data={{
          title: `Kontaktai`,
          description: `Viešosios įstaigos „Mindify“ kontaktai: el. pašto adresas, telefono nr. ir rekvizitai`,
        }}
      />

      <Container>
        <Heading1>Kontaktai</Heading1>

        <Cols>
          <div>
            <Contacts as="ul">
              <li>
                <Anchor as={Link} to="mailto:info@mindify.lt">
                  info@mindify.lt
                </Anchor>
              </li>

              <li>
                <Anchor as={Link} to="tel:+37065915106">
                  +370 659 15106
                </Anchor>
              </li>
            </Contacts>

            <Requisites>
              <p>
                Viešoji įstaiga „Mindify“
                <br />
                Įmonės kodas 305643672
                <br />
                LT29 7300 0101 6516 7157, Swedbank
                <br />
              </p>
            </Requisites>
          </div>

          <div>
            <ContactForm />
          </div>
        </Cols>
      </Container>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
}

export default ContactPage

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Link from "../components/link"
import { Heading1, Heading3 } from "../components/styled/heading"

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${rem(240)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${rem(120)};
  }
`

const List = styled.div`
  max-width: ${rem(880)};
  margin: 0 auto;
  padding-top: ${em(40)};
`

const News = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
    align-items: flex-start;
  }

  & + & {
    margin-top: ${em(20)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(60)};
    }
  }

  .-title {
    padding-right: ${em(80)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-right: 0;
      order: 1;
    }

    a {
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.blue};
      }

      &:active {
        opacity: 0.5;
      }
    }

    &__date {
      margin-top: ${em(10)};
      display: block;
      opacity: 0.7;
    }
  }

  .-img {
    a {
      display: block;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    .gatsby-image-wrapper {
      width: ${em(200)};
      height: ${em(120)};
    }
  }
`

const NewsPage = ({ data }) => {
  const anyNews = data.news.nodes.length > 0
  const metaDescription = anyNews
    ? data.news.nodes.map((i) => i.frontmatter.title).join(`; `)
    : undefined

  return (
    <Layout>
      <Meta
        data={{
          title: `Naujienos`,
          description: metaDescription,
        }}
      />

      <Container>
        <Heading1>Naujienos</Heading1>

        {anyNews && (
          <List>
            {data.news.nodes.map((news, i) => (
              <News key={i}>
                <header className="-title">
                  <Heading3 as="h2">
                    <Link
                      to={news.path}
                      dangerouslySetInnerHTML={{
                        __html: news.frontmatter.title,
                      }}
                    />
                  </Heading3>

                  <time
                    className="-title__date"
                    dateTime={news.frontmatter.date}
                    dangerouslySetInnerHTML={{ __html: news.frontmatter.date }}
                  />
                </header>

                {news.frontmatter.image && (
                  <figure className="-img">
                    <Link to={news.path}>
                      <GatsbyImage
                        image={
                          news.frontmatter.image.childImageSharp.gatsbyImageData
                        }
                        alt={news.frontmatter.title}
                      />
                    </Link>
                  </figure>
                )}
              </News>
            ))}
          </List>
        )}
      </Container>
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.object,
}

export default NewsPage

export const pageQuery = graphql`
  query {
    news: allMarkdownRemark(
      filter: { type: { eq: "naujienos" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        path
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import Link from "../link"
import { ReactComponent as SvgLogo } from "../../assets/images/logo.svg"

const Container = styled.header`
  ${({ theme }) => theme.grid.container()}

  > div {
    padding: ${rem(60)};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.mq.largeDown} {
      padding: ${rem(20)} 0 ${rem(40)};
    }
  }
`

const Logo = styled.div`
  width: ${em(170)};
  height: ${em(60)};
  order: 1;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: ${em(120)};
    height: ${em(45)};
  }

  a,
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  a {
    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }
`

const Toggle = styled.div`
  display: none;

  @media ${({ theme }) => theme.mq.largeDown} {
    order: 2;
    display: block;
  }

  button {
    ${({ theme }) => theme.fonts.set(`secondary`, `bold`)};

    padding: ${em(6)} ${em(20)};
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid;
    border-radius: ${rem(10)};

    ${({ active }) =>
      active &&
      css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.blue};
        border-color: transparent;
      `}
  }
`

const Nav = styled.nav`
  ${({ theme }) => theme.fonts.set(`secondary`, `bold`)};

  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: none;
    margin-top: ${em(20)};
    padding: ${em(20)} 0;
    flex: 1 1 100%;
    order: 3;
    text-align: center;
    border: solid rgba(0, 0, 0, 0.1);
    border-width: 1px 0;

    ${({ active }) =>
      active &&
      css`
        display: block;
      `}
  }

  a {
    margin-right: ${em(20)};
    font-size: ${em(22)};

    @media ${({ theme }) => theme.mq.largeDown} {
      margin-top: ${em(10)};
      display: block;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }

    &.\\--active {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

const items = [
  {
    url: `/paslaugos`,
    title: `Paslaugos`,
  },
  {
    url: `/apie`,
    title: `Apie`,
  },
  {
    url: `/kontaktai`,
    title: `Kontaktai`,
  },
  {
    url: `/parama`,
    title: `1,2 % PARAMA`,
  },
  {
    url: `/naujienos`,
    title: `Naujienos`,
  },
]

const Header = ({ activeNavItem }) => {
  const [mobileActive, setMobileActive] = useState(false)

  const toggleClick = () => setMobileActive(!mobileActive)

  return (
    <Container>
      <div>
        <Logo>
          <Link to="/" title="Mindify">
            <SvgLogo aria-label="Mindify" />
          </Link>
        </Logo>

        <Toggle active={mobileActive}>
          <button type="button" onClick={toggleClick}>
            Meniu
          </button>
        </Toggle>

        <Nav active={mobileActive}>
          {items.map((item, i) => (
            <Link
              key={i}
              to={item.url}
              activeClassName="--active"
              partiallyActive={true}
              className={activeNavItem == item.url ? `--active` : undefined}
            >
              {item.title}
            </Link>
          ))}
        </Nav>
      </div>
    </Container>
  )
}

Header.propTypes = {
  activeNavItem: PropTypes.string,
}

export default Header

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Styled from "../components/styled"
import { Heading1 } from "../components/styled/heading"
import FancyCta from "../components/fancy-cta"

const Container = styled.article`
  ${({ theme }) => theme.grid.container()}
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
  }

  > .-title {
    max-width: ${em(640)};
    padding-right: ${em(80)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding-right: 0;
      padding-bottom: ${em(20)};
    }
  }

  > .-img {
    position: relative;

    &::before {
      content: "";
      width: 100vw;
      height: 100vw;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.yellowLightest};
      border-radius: 50%;

      @media ${({ theme }) => theme.mq.mediumDown} {
        width: 200vw;
        height: 200vw;
      }
    }

    .gatsby-image-wrapper {
      max-width: ${em(420)};
      max-height: ${em(420)};
      width: 70vw;
      height: 70vw;
      z-index: 1;
      overflow: hidden;
      border-radius: 50%;
    }
  }
`

const Text = styled.div`
  max-width: ${rem(768)};
  padding: ${rem(60)};
  padding-top: ${rem(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: 0;
    padding-top: ${rem(40)};
  }
`

const ServicePage = ({ data: { post } }) => {
  return (
    <Layout headerActiveNavItem="/paslaugos">
      <Meta
        data={{
          title: post.frontmatter.title,
          description: post.html,
        }}
      />

      <Container>
        <Header>
          <div className="-title">
            <Heading1
              dangerouslySetInnerHTML={{ __html: post.frontmatter.title }}
            />
          </div>

          {post.frontmatter.image && (
            <figure className="-img">
              <GatsbyImage
                image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.title}
              />
            </figure>
          )}
        </Header>

        <Text>
          <Styled dangerouslySetInnerHTML={{ __html: post.html }} />
        </Text>
      </Container>

      <FancyCta
        heading={post.frontmatter.cta}
        title="Susisiekime!"
        url="/kontaktai"
      />
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.object,
}

export default ServicePage

export const pageQuery = graphql`
  query ($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        cta
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

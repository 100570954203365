import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem, clearFix } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Styled from "../components/styled"
import { Heading1 } from "../components/styled/heading"

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${rem(240)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${rem(120)};
  }
`

const Cols = styled.div`
  margin-top: ${rem(30)};
  padding: 0 ${rem(60)};
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    padding: 0;
    flex-direction: column;
  }

  > div {
    ${clearFix()}

    width: calc(50% - ${rem(50)});
    flex-shrink: 0;
    position: relative;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: ${rem(30)};
      }
    }
  }
`

const Image = styled.figure`
  margin-top: ${rem(30)};
  padding: 0 ${rem(60)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(10)};
    padding: 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const DonatePage = ({ data }) => {
  return (
    <Layout>
      <Meta
        data={{
          title: `1,2% parama`,
          description: `Kartu mes stipresni ir galime kur kas daugiau!`,
        }}
      />

      <Container>
        <Heading1>1,2% parama</Heading1>

        <Image>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt="Kartu mes stipresni ir galime kur kas daugiau!"
          />
        </Image>

        <Cols>
          <Styled>
            <p>
              Remdami Mindify veiklas prisidedate prie socialinių idėjų ir
              sveikatinimo projektų įgyvendinimo bei jų sklaidos didinimo.
              Kviečiame geranoriškumo idėjoms skleistis drauge! 🙌
            </p>
          </Styled>

          <Styled>
            <ul>
              <li>
                1,2% paramos sąskaitos numeris:
                <br />
                <strong>LT50 7300 0101 6992 7539</strong>
              </li>

              <li>
                Paramos gavėjo identifikacinis numeris (kodas):
                <br />
                <strong>305643672</strong>
              </li>

              <li>
                Paramos gavėjo pavadinimas:
                <br />
                <strong>VšĮ Mindify</strong>
              </li>

              <li>
                Buveinės adresas:
                <br />
                <strong>Tauralaukio g. 16c, 92347 Klaipėda</strong>
              </li>

              <li>
                Banko pavadinimas:
                <br />
                <strong>AB Swedbank</strong>
              </li>

              <li>
                Banko kodas:
                <br />
                <strong>7300</strong>
              </li>
            </ul>
          </Styled>
        </Cols>
      </Container>
    </Layout>
  )
}

DonatePage.propTypes = {
  data: PropTypes.object,
}

export default DonatePage

export const pageQuery = graphql`
  query {
    image: file(
      sourceInstanceName: { eq: "content-images" }
      relativePath: { eq: "parama.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

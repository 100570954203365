export default {
  white: `#fff`,
  black: `#222`,
  blue: `#0096ff`,
  blueDarkest: `#2c3d44`,
  yellowLighter: `#f0eee2`,
  yellowLightest: `#fcfbee`,
  red: `#dd485c`,
  green: `#36b369`,
}

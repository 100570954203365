import styled, { css } from "styled-components"
import { em } from "polished"

const paragraphStyles = css`
  font-size: ${em(20)};
  line-height: 1.55;
  text-align: justify;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(18)};
  }
`

const Paragraph = styled.p`
  ${paragraphStyles}
`

export { Paragraph, paragraphStyles }

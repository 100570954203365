import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem, clearFix } from "polished"

import Button from "../button"
import Link from "../link"
import Styled from "../styled"
import { Heading1 } from "../styled/heading"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  > h2 {
    max-width: ${rem(640)};
    margin: 0 auto;
    text-align: center;
  }
`

const Cols = styled.div`
  margin-top: ${em(80)};
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
  }

  > * {
    width: 50%;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: auto;
    }

    &:first-child {
      position: relative;
    }
  }

  > .-img {
    ${clearFix()}

    .gatsby-image-wrapper {
      width: 55vw;
      max-width: ${rem(960)};
      float: right;

      @media ${({ theme }) => theme.mq.mediumDown} {
        width: 100%;
        float: none;
      }
    }

    > figcaption {
      position: absolute;
      bottom: ${rem(40)};
      right: 0;
      transform: translateX(50%);

      @media ${({ theme }) => theme.mq.mediumDown} {
        right: ${rem(20)};
        bottom: 0;
        transform: translateY(50%);
        font-size: ${em(14)};
      }
    }
  }

  > .-text {
    padding: ${rem(40)} 0 ${rem(200)} ${rem(40)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding: ${rem(70)} 0 0;
    }

    > * {
      max-width: ${rem(460)};
    }
  }
`

const AboutBrief = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        sourceInstanceName: { eq: "content-images" }
        relativePath: { eq: "inovatyvus-svietimas-vaikams-jaunimui.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container>
      <Heading1 as="h2">Inovatyvus švietimas vaikams ir jaunimui</Heading1>

      <Cols>
        <figure className="-img">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="Inovatyvus švietimas vaikams ir jaunimui"
          />

          <figcaption>
            <Button to="/apie">Apie mus</Button>
          </figcaption>
        </figure>

        <div className="-text">
          <Styled>
            <p>
              Viešoji įstaiga Mindify yra ne pelno siekianti organizacija.
              Pagrindinė veikla nukreipta į vaikų ir jaunimo neformalaus ugdymo
              organizavimą bei įvairių socialinių iniciatyvų įgyvendinimą.
              Organizacija vykdo visuomeninę, kultūrinę ir švietėjišką veiklą,
              kurios spektras susideda iš interaktyvių edukacinių užsiėmimų
              vaikams, paaugliams ir jaunimui, meninių programų įgyvendinimo,
              bei sveikos gyvensenos diegimo.
            </p>

            <p>
              Šiandien vis dažniau kalbame apie sveikatą, jos stiprinimą,
              sveikatos problemas bei sveiko gyvenimo būdo nuostatų
              formavimo(si) svarbą vaikystėje, paauglystėje ir jaunystėje.
              Naujausių tyrimų duomenimis, kasmet blogėja Lietuvos vaikų
              sveikata, mūsų šalies vaikai jaučiasi gerokai nelaimingesni negu
              kitų Europos šalių vaikai, blogiau vertina savo sveikatą.
              {` `}
              <Link to="/apie">Kodėl taip yra?</Link>
            </p>
          </Styled>
        </div>
      </Cols>
    </Container>
  )
}

export default AboutBrief

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { em } from "polished"

import Button from "../button"

const Container = styled.section`
  padding-top: 30vh;
  padding-bottom: 30vh;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
`

const Heading = styled.h2`
  ${({ theme }) => theme.grid.container(`xxnarrow`)}
  ${({ theme }) => theme.fonts.set(`secondary`, `bold`)};

  max-width: ${em(480)};
  padding-bottom: ${em(50)};
  font-size: ${em(20)};
  text-align: center;
`

const Cta = styled.div`
  text-align: center;
  background-repeat: repeat-x;
  background-position: center center;
  background-size: ${em(36)};
  background-image: url("data:image/svg+xml,${({ theme }) =>
    encodeURIComponent(`
      <svg viewBox="0 0 43 66" xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(-1)" fill="${theme.colors.yellowLighter}" d="M1 .028a4.5 4.5 0 010 8.944zM44 14.006V65.97c-.958-.044-1.767-.388-2.428-1.033a3.387 3.387 0 01-1.065-2.52V27.801l-14.71 21.54c-.342.5-.737.901-1.176 1.22-.064.05-.129.1-.203.149-.29.19-.618.344-.963.452-.008.003-.017.008-.025.01-.879.283-1.69.207-2.439-.217-.695-.342-1.295-.867-1.789-1.59L4.492 27.827v34.614c0 .99-.354 1.829-1.063 2.52C2.719 65.655 1.839 66 .787 66L1 65.994V14.03c.08.004.159.01.239.02l.21.032a3.535 3.535 0 011.662.724c.101.074.198.154.295.238l.024.019.01.012c.31.272.6.581.855.956l18.198 26.648 18.215-26.673c.255-.374.545-.683.854-.955l.01-.013c.008-.007.017-.012.025-.02.096-.082.193-.163.295-.236a3.529 3.529 0 011.663-.725c.21-.038.417-.057.618-.053l-.173.002zM44 .028v8.944a4.5 4.5 0 010-8.944z"/>
      </svg>
  `)}");

  @media ${({ theme }) => theme.mq.mediumDown} {
    background-size: ${em(32)};
  }

  > * {
    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: ${em(18)};
    }
  }
`

const FancyCta = ({ heading, title, url }) => {
  if (!title || !url) return null

  return (
    <Container>
      {heading && <Heading dangerouslySetInnerHTML={{ __html: heading }} />}

      <Cta>
        <Button to={url} size="large">
          {title}
        </Button>
      </Cta>
    </Container>
  )
}

FancyCta.propTypes = {
  heading: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default FancyCta

// focuses the first errored input on submit

import { useEffect } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

const FocusError = ({ formEl }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating && formEl) {
      const keys = Object.keys(errors)
      if (keys.length) {
        const errorElement = formEl.querySelector(`[name=${keys[0]}]`)
        if (errorElement) errorElement.focus()
      }
    }
  }, [formEl, errors, isSubmitting, isValidating])

  return null
}

FocusError.propTypes = {
  formEl: PropTypes.object,
}

export default FocusError

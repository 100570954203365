import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem, hideVisually } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import ServicesAnnouncer from "../components/services-announcer"
import AboutBrief from "../components/about-brief"
import FancyCta from "../components/fancy-cta"

const Container = styled.div`
  > * + * {
    margin-top: ${rem(160)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(120)};
    }
  }

  > *:last-child {
    margin-top: ${rem(80)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  ${hideVisually()}
`

const IndexPage = () => {
  return (
    <Layout>
      <Meta
        data={{
          title: `Mindify – inovatyvus švietimas vaikams ir jaunimui`,
          titleOverridePattern: true,
        }}
      />

      <Title>Mindify – inovatyvus švietimas vaikams ir jaunimui</Title>

      <Container>
        <ServicesAnnouncer />

        <AboutBrief />

        <FancyCta
          heading="Norite mokymų savo bendruomenėje ar mokykloje?"
          title="Susisiekime!"
          url="/kontaktai"
        />
      </Container>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage
